import { imageAdapter } from '@/adapters/constructor/utils/image';
import { linkAdapter } from '@/adapters/constructor/utils/link';

export default ({ data }) => {
  return {
    title: data.title,
    date: data.date,
    text: data.text,
    link: linkAdapter(data.link),
    logo: imageAdapter(data.logo),
    arrow: imageAdapter(data.arrow, data.arrow_mobile),
  };
};
